.textColorLightGreen {
  color: var(--green-200);
}

.textColorGreen {
  color: var(--green-600);
}

.textColorOrange {
  color: var(--orange-600);
}

.textColorGrey {
  color: var(--surface-400);
}

@font-face {
  font-family: 'digital';
  src: url('./assets/fonts/advanced_dot_digital-7-webfont.woff2') format('woff2'),
        url('./assets/fonts/advanced_dot_digital-7-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

.textColorRed {
  color: var(--red-500);
}


.vdgs-container {
  font-family: 'digital';
  color: var(--orange-400);
  background-color: var(--surface-900);
}


.login-card {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20vh;
  max-width: 80vw;
  
}



@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20vh;
    max-width: 80vw;
    animation: App-logo-spin infinite 30s linear;
  }
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.datatable .table-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media screen and (max-width: 960px) {
  .datatable .table-header {
      align-items: flex-start;
  }
}

.footer{
  position: fixed;
  bottom: 0px;
  width:100%;
  z-index: 102;
  background-color: rgb(226, 225, 225);
  }